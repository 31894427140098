<template>
  <div class="activity_page">
    <div class="activity_page_top" style="height: 80px">
      <div @click="fh()">
        <img :src="require('@/assets/image/fh.png')" alt="" />
        返回
      </div>
    </div>
    <div class="logo">
      <img src="@/assets/image/adultTest/logo.png" alt="" />
    </div>
    <div class="theme">
      <img src="@/assets/image/adultTest/text.png" alt="" />
    </div>
    <div class="logIn_form">
      <div class="input_form">
        <div v-if="logInType == 'pre'">
          <div class="title">查詢入口</div>
          <div class="hr-box"><div class="hr"></div></div>
        </div>
        <div v-if="logInType != 'pre'">
          <div class="options_title">
            <div
              :class="item.code == logInType ? 'highlight' : null"
              v-for="(item, index) in typeOptions"
              :key="index"
              @click="logInType = item.code"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="bor"></div>
        </div>

        <div
          class="input-box"
          style="overflow: hidden"
          v-if="logInType == 'result'"
        >
          <van-field
            v-model="form.testCode"
            placeholder="請輸入考生號"
            type="tel"
          />
        </div>

        <div
          class="input-box"
          style="overflow: hidden"
          v-if="logInType != 'result'"
        >
          <van-field v-model="form.name" placeholder="請輸入姓名" type="tel" />
        </div>

        <div
          class="input-box"
          style="overflow: hidden"
          v-if="logInType == 'cert'"
        >
          <van-field
            v-model="form.code"
            placeholder="請輸入證書編號"
            type="number"
            @keyup.enter.native="submitForm"
          />
        </div>

        <div style="height: 72px" v-if="logInType == 'cert'"></div>
        <div class="input-box" v-if="logInType != 'cert'">
          <CountryCodeSelector
            :searchbar="false"
            :countryCode.sync="areaCode"
            style="
              display: flex;
              align-items: center;
              justify-content: space-around;
              width: 110px;
            "
          ></CountryCodeSelector>
          <van-field
            v-model="form.phone"
            placeholder="请输入手機號"
            type="number"
            class="fill"
            style="max-height: 100%; display: flex; align-items: center"
          />
        </div>
        <div
          class="input-box"
          style="overflow: hidden"
          v-if="logInType != 'cert'"
        >
          <van-field
            v-model="form.verifyCode"
            placeholder="請輸入驗證碼"
            type="number"
            class="fill"
            @keyup.enter.native="submitForm"
          >
            <template #button>
              <div class="code_button" @click="getVerificationCode">
                {{ codeText }}
              </div>
            </template>
          </van-field>
        </div>
        <div class="button-box" @click="submitForm">
          <span>{{
            logInType == 'pre'
              ? '預約查詢'
              : logInType == 'cert'
              ? '證書查詢'
              : '成績查詢'
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Toast, Dialog, Icon, Button } from 'vant'
import CountryCodeSelector from '@/components/CountryCodeSelector'
import API from '@/api/index'

export default {
  name: 'TestLogIn',
  components: {
    CountryCodeSelector,
    [Field.name]: Field,
    [Toast.name]: Toast,
    [Dialog.name]: Dialog,
    [Icon.name]: Icon,
    [Button.name]: Button
  },
  data() {
    return {
      areaCode: 852,
      codeText: '發送驗證碼',
      isGetCode: false, //发送状态
      form: {
        verifyCode: null, // 验证码
        phone: null, // 手機號
        name: null, // 姓名
        testCode: null //學生編號
      },
      logInType: 'pre',
      typeOptions: [
        { name: '成績查詢', code: 'result' },
        { name: '證書查詢', code: 'cert' }
      ]
    }
  },
  watch: {
    $route(to, from) {
      console.log(to.path)
      if (this.$route.query.type) {
        this.logInType = this.$route.query.type
      }
    }
  },
  created() {
    const resizeEvt =
      'orientationchange' in window ? 'orientationchange' : 'resize'
    const resetRecalc = () => {
      const __clientWidth =
        document.documentElement.clientWidth || document.body.clientWidth
      if (__clientWidth < 1024) {
				let logInType = this.$route.query.type
        if (logInType == 'pre') {
          this.$router.replace({ name: 'reservationInquire' })
        } else {
          this.$router.replace({ name: 'query' })
        }
      }
    }
    window.addEventListener(resizeEvt, resetRecalc.bind(this), false)
    window.addEventListener('load', resetRecalc.bind(this), false)
  },
  mounted() {
    if (this.$route.query.type) {
      this.logInType = this.$route.query.type
    }
  },
  methods: {
    initFrom() {
      this.form = {
        verifyCode: null, // 验证码
        phone: null, // 手機號
        name: null, // 姓名
        testCode: null //學生編號
      }
    },
    fh() {
      this.$router.replace({ path: '/' })
    },
    // 获取验证码
    getVerificationCode() {
      if (this.isGetCode) return false
      let regPhone = this.areaCode === 86 ? /^1[3456789]\d{9}$/ : /^[0-9]*$/
      const { phone } = this.form

      if (!phone) {
        Toast('請輸入手機號碼')
        return false
      }

      if (!regPhone.test(phone)) {
        Toast('手機號碼有誤，請重新輸入')
        return false
      }

      const phoneList = []
      phoneList.push('+' + this.areaCode + '-' + phone)
      const params = {
        appType: 12,
        verifyType: 9,
        phoneList,
        smsTemplateType: 10,
        signType: 1
      }
      API.sendVerificationCode(params)
        .then((res) => {
          console.log(res)
          if (res.code == 0) {
            //请求成功
            Toast.success('驗證碼發送成功')
            this.countFun(59)
          } else {
            Toast.fail(res.msg)
          }
        })
        .catch((error) => {
          //请求失败
          Toast.fail('error')
          console.log(error)
        })
    },
    countFun(time) {
      this.isGetCode = true
      this.codeText = `${time}s`
      let timer = setInterval(() => {
        if (time > 1) {
          time--
          this.codeText = time >= 10 ? `${time}s` : `0${time}s`
        } else {
          clearInterval(timer)
          this.codeText = '發送驗證碼'
          this.isGetCode = false
          return true
        }
      }, 1000)
    },
    // 提交
    submitForm() {
      Toast.loading({
        message: '查詢中...',
        forbidClick: true,
        overlay: true,
        duration: 0
      })
      const { name, phone, verifyCode, code, testCode } = this.form
      if (this.logInType == 'result') {
        let regPhone = this.areaCode === 86 ? /^1[3456789]\d{9}$/ : /^[0-9]*$/
        if (!testCode || !testCode.trim()) {
          Toast('請輸入考生號')
          return false
        } else if (!phone) {
          Toast('请输入手機號码')
          return false
        } else if (!regPhone.test(phone)) {
          Toast('手機號碼有誤，請重新輸入')
          return false
        } else if (!verifyCode) {
          Toast('驗證碼不能為空')
          return false
        }
        const params = {
          prefix: this.areaCode,
          testCode,
          phone,
          verifyCode
        }
        API.getQuerySore(params)
          .then((res) => {
            console.log(res, 'res')
            Toast.clear()
            if (res.code == 0) {
              this.initFrom()
              this.$router.push({
                name: 'informationFilling',
                params: { type: 'result', data: res.data }
              })
            } else {
              Toast(res.msg)
            }
          })
          .catch((err) => {
            Toast.clear()
          })
      } else if (this.logInType != 'cert') {
        let regPhone = this.areaCode === 86 ? /^1[3456789]\d{9}$/ : /^[0-9]*$/
        if (!name || !name.trim()) {
          Toast('請輸入姓名')
          return false
        } else if (!phone) {
          Toast('请输入手機號码')
          return false
        } else if (!regPhone.test(phone)) {
          Toast('手機號碼有誤，請重新輸入')
          return false
        } else if (!verifyCode) {
          Toast('驗證碼不能為空')
          return false
        }
        const params = {
          prefix: this.areaCode,
          name,
          phone,
          verifyCode
        }

        if (this.logInType == 'pre') {
          API.getReservation(params)
            .then((res) => {
              console.log(res, 'res')
              Toast.clear()
              if (res.code == 0) {
                this.initFrom()
                window.sessionStorage.setItem(
                  'mimi',
                  encodeURI(JSON.stringify(params))
                )
                this.$router.push({
                  name: 'informationFilling',
                  params: { type: 'showList', data: res.rows }
                })
              } else {
                Toast(res.msg)
              }
            })
            .catch((err) => {
              Toast.clear()
            })
        }
      } else {
        if (!name || !name.trim()) {
          Toast('請輸入姓名')
          return false
        } else if (!code) {
          Toast('請輸入證書編號')
          return false
        }
        let params = null
        API.getCertificate(null, name, code)
          .then((res) => {
            console.log(res, '成绩')
            this.$toast.clear()
            if (res.code == 0) {
              // window.location.href=res.data
              // this.$router.push({ path: '/informationFilling?url='+JSON.stringify(res.data)})
              this.initFrom()
              this.$router.push({
                name: 'informationFilling',
                params: { type: 'cert', data: res.data }
              })
            } else {
              Toast(res.msg)
            }
          })
          .catch((err) => {
            this.$toast.clear()
          })
        // console.log('證書查詢')
        // setTimeout(() => {
        //   Toast.clear()
        //   Toast('调试中')
        // }, 1000)
        // this.$router.push({
        //   name: 'informationFilling',
        //   params: { type: 'cert' }
        // })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.activity_page_top {
  width: 53%;
  margin: auto;
  div {
    height: 100%;
    display: flex;
    align-items: center;
    color: #ffffff;
    font-size: 16px;
    cursor: pointer;

    img {
      width: 40px;
      height: 40px;
    }
  }
}
.activity_page {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  // display: table;
  background: url('~@/assets/image/adultTest/page_background.jpg') no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  .logo {
    margin: 0 auto;
    display: table;
    background: #ffffff;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
    padding: 10px 16px;
    box-sizing: border-box;
    img {
      width: 48px;
      height: 60px;
    }
  }
  .theme {
    margin: 24px auto 0 auto;
    img {
      width: 571px;
    }
  }
  .logIn_form {
    margin: 47px auto 125px auto;
    width: 516px;
    height: 486px;
    background: #ffffff;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.12);
    border-radius: 20px;

    .input_form {
      width: 380px;
      margin: 0 auto;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      .title {
        display: table;
        font-size: 20px;
        font-weight: bold;
        color: #343434;
        line-height: 23px;
        padding-top: 68px;
      }
      .options_title {
        font-size: 20px;
        color: #343434;
        line-height: 23px;
        padding-top: 68px;
        display: flex;
        div {
          margin-right: 32px;
          cursor: pointer;
        }
        .highlight {
          font-weight: bold;
          color: #122ab0;
          position: relative;
          &::before {
            content: '';
            position: absolute;
            bottom: -20px;
            border-radius: 2px;
            background: #122ab0;
            height: 4px;
            width: 40px;
          }
        }
      }

      .bor {
        margin-top: 20px;
        border-bottom: 1px solid #e6e6e6;
      }
      .button-box {
        margin-top: 32px;
        cursor: pointer;
        height: 52px;
        background: #122ab0;
        border-radius: 60px;
        span {
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 52px;
        }
      }
      .hr-box {
        margin-top: 20px;
        border-bottom: 1px solid #e6e6e6;
        .hr {
          border-radius: 2px;
          background: #122ab0;
          height: 4px;
          width: 40px;
        }
      }
      .input-box {
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 48px;
        margin-top: 24px;
        border: 1px solid #e6e6e6;
        border-radius: 8px;
        box-sizing: border-box;
        .fill {
          flex: 1;
          font-size: 14px;
          .code_button {
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #122ab0;
            line-height: 15px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
/deep/ .van-field__control {
  font-size: 16px;
}
/deep/ .CountryCodeSelectorContainer {
  width: 150px;
  .arrow-down {
    width: 12px;
    height: 12px;
  }
}
</style>
